import * as Radix from "@radix-ui/themes";
import { BoxProps as RadixBoxProps } from "@radix-ui/themes/dist/cjs/components/box";
import styles from "./StyledBox.module.scss";
import { cn } from "@lib/utils";

type StyledBoxBgColorType = "gray" | "beige" | "pink" | "mint";

type StyledBoxOwnProps = {
  bgColor: StyledBoxBgColorType;
  radius: "small" | "medium" | "large";
  defaultPadding?: boolean;
};

type StyledBoxProps = RadixBoxProps & StyledBoxOwnProps;

function StyledBox({
  bgColor,
  radius,
  defaultPadding,
  className: propClassName, // Renamed to avoid naming conflict
  ...rest
}: StyledBoxProps) {
  let localClassName;
  switch (radius) {
    case "small":
      localClassName = styles.styledBoxSmall;
      break;
    case "medium":
      localClassName = styles.styledBoxMedium;
      break;
    case "large":
      localClassName = styles.styledBoxLarge;
      break;
    default:
      localClassName = styles.styledBoxMedium; // default size
  }

  // Combine local className with className from props
  const combinedClassName = cn(localClassName, propClassName);

  return (
    <Radix.Box
      {...rest}
      {...(defaultPadding ? { p: { initial: "5", sm: "6", md: "7" } } : {})}
      className={combinedClassName}
      style={{
        backgroundColor: getBgColor(bgColor),
      }}
    />
  );
}

function getBgColor(bgColor: StyledBoxProps["bgColor"]): string {
  switch (bgColor) {
    case "gray":
      return "var(--colorV2-grey-light)";
    case "beige":
      return "var(--colorV2-beige)";
    case "pink":
      return "var(--colorV2-pink)";
    case "mint":
      return "var(--colorV2-light-green)";
  }
}

export { StyledBox };
export type { StyledBoxProps, StyledBoxBgColorType };
