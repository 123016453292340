"use client";
import React, { useEffect, useState } from "react";
import { Box, Flex, Portal } from "@radix-ui/themes";
import {
  TherapistSpotlightVideoOverlay,
  VideoCarouselVideo,
} from "@components/Directory/TherapistSpotlightVideos/TherapistSpotlightVideoOverlay";
import Carousel from "@components/VideoCarousel/Carousel";
import { RemoveScroll } from "react-remove-scroll";
import { ICTheme } from "@/design-system/ICTheme";
import { StyledBox } from "@/design-system/components/styled-container/StyledBox";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { useTranslations } from "next-intl";
import { Profile } from "@/api/entities/profile";
import { DirectoryPicture } from "@components/Profile/Picture/DirectoryPicture";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import styles from "./TherapistSpotlightVideos.module.scss";
import useScroller from "@/hooks/useScroller";
import {
  ScrollLeftButton,
  ScrollRightButton,
} from "@/design-system/components/ScrollButton/ScrollButtons";
import { ampli } from "@/analytics/ampli";
import { NonMobilePortraitView } from "@/design-system/responsive-helpers/NonMobilePortraitView";

function TherapistSpotlightVideos({ profiles }: { profiles: Profile[] }) {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const videos = extractVideos(profiles);

  useEffect(() => {
    if (selectedIndex !== null) {
      ampli.featuredTherapistVideoViewed({
        index: selectedIndex,
        therapist_user_id: videos[selectedIndex].therapistUserId,
      });
    }
  }, [videos, selectedIndex]);

  if (videos.length === 0) {
    return null;
  }

  // copy videos until we get to 10
  if (process.env.NEXT_PUBLIC_APP_ENV !== "production") {
    while (videos.length < 10) {
      videos.push(videos[0]);
    }
  }

  return (
    <>
      <Launcher videos={videos} onSelectIndex={setSelectedIndex} />
      {selectedIndex !== null && (
        <Portal>
          <ICTheme>
            <RemoveScroll>
              <Carousel
                videos={videos}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                videoOverlay={TherapistSpotlightVideoOverlay}
                autoAdvanceDelay={3}
                hideControlsDelay={3}
              />
            </RemoveScroll>
          </ICTheme>
        </Portal>
      )}
    </>
  );
}

type LauncherProps = {
  videos: VideoCarouselVideo[];
  onSelectIndex: (index: number) => void;
};

function Launcher({ videos, onSelectIndex }: LauncherProps) {
  const t = useTranslations("TherapistSpotlightVideos");
  const horizontalSpacing = { initial: "5", sm: "7" };
  const { scrollerRef, canScrollLeft, canScrollRight, scroll } = useScroller();
  return (
    <StyledBox
      bgColor={"beige"}
      radius={"small"}
      py={{ initial: "5", sm: "7" }}
    >
      <Flex direction={"column"} gap={"4"} align={"center"}>
        <HeadingV2
          textStyle={"Headline L"}
          as={"h3"}
          mx={horizontalSpacing}
          align={"center"}
        >
          {t("title")}
        </HeadingV2>
        <NonMobilePortraitView asChild>
          <TextV2
            textStyle={"Body M"}
            style={{ maxWidth: "650px" }}
            align={"center"}
            mx={horizontalSpacing}
          >
            {t("subtitle")}
          </TextV2>
        </NonMobilePortraitView>
        <Flex px={horizontalSpacing}>
          <ButtonV2
            size={"3"}
            color={"gray"}
            variant={"outline"}
            style={{ width: "100%" }}
            onClick={() => onSelectIndex(0)}
          >
            {t("explore_all_cta")}
          </ButtonV2>
        </Flex>
        <Box
          position={"relative"}
          flexGrow={"1"}
          minWidth={"0"}
          my={"3"}
          width={"100%"}
          className={styles.scrollWrapper}
        >
          <Flex
            direction={"row"}
            gap={{ initial: "3", sm: "4" }}
            overflow={"scroll"}
            width={"100%"}
            ref={scrollerRef}
            px={horizontalSpacing}
          >
            {videos.map((_video, index) => (
              <Box
                className={styles.imageWrapper}
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => onSelectIndex(index)}
              >
                <DirectoryPicture
                  showPlayButton={false}
                  pictureInfo={{
                    profilePictureUrl: _video.pictureUrl,
                    fullName: _video.name,
                  }}
                />
              </Box>
            ))}
          </Flex>
          <ScrollLeftButton
            onClick={() => scroll("left")}
            canScrollLeft={canScrollLeft}
            className={styles.overlayScrollButtonLeft}
          />
          <ScrollRightButton
            onClick={() => scroll("right")}
            canScrollRight={canScrollRight}
            className={styles.overlayScrollButtonRight}
          />
        </Box>
      </Flex>
    </StyledBox>
  );
}

function extractVideos(profiles: Profile[]) {
  const videos = profiles.map((profile) => {
    if (!profile.active_videos || profile.active_videos.length === 0) {
      return null;
    }

    const portraitVideo = profile.active_videos.find(
      (video) => video.orientation === "portrait",
    );
    if (!portraitVideo) {
      return null;
    }
    return {
      streamUrl: portraitVideo.stream_url,
      thumbnailUrl: portraitVideo.still_image_url_med,
      firstName: profile.firstname,
      name: profile.full_name,
      title: profile.jobtitle,
      cityName: profile.city.name,
      pictureUrl: profile.profile_picture_url,
      supportsInPersonTherapy: profile.supports_in_person_therapy,
      supportsVideoTherapy: profile.supports_video_therapy,
      supportsTextTherapy: profile.supports_text_therapy,
      partialUri: profile.partial_uri,
      therapistUserId: profile.user.id.toString(),
    };
  });

  function isNotNull<T>(v: T | null): v is T {
    return v !== null;
  }

  return videos.filter(isNotNull);
}

export { TherapistSpotlightVideos };
